import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export default function SimpleContainer() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box sx={{ 
          height: '100vh', 
        }} >
          <div class="center">
            <h1>
              <span>Gianpa</span>
              <span>Gianpa</span>
              <span>Gianpa</span>
            </h1>
          </div>
        </Box>
      </Container>
    </React.Fragment>
  );
}