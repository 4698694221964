import Home from './pages/Home';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#888',
    },
    secondary: {
      main: '#888',
    },
    background: {
      default: '#252734',
      paper: '#252734',
    },
    text: {
      primary: '#888',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Home />
    </ThemeProvider>
  );
}

export default App;
